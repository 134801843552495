import { Component, OnInit } from '@angular/core';
import { IProductButton } from 'src/services/models/product-button';
import { ITestimonial } from 'src/services/models/testimonial';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.sass']
})
export class HomepageComponent implements OnInit {

  public testimonials: ITestimonial[] = [];
  public productButtons: IProductButton[] = [];

  constructor() {

    this.testimonials.push(...[{
      title: 'Exceptional ROI',
      description: `"Possibly the best investment I ever made. It's likely paid for itself already." [three months]`,
      signature: ['Craig Barker', 'Managing Director', 'A-Line Bulding System, VIC'],
      image_url: '/assets/homepage/testimonials/testimonial_craigbarler@600w-full.png'
    }, {
      title: 'Game-changing efficiency',
      description: `"I just thought I would let you know that we cut our first days work today fully utilising the ProfiStop. The result was approximately 1 - 1.5 hours faster cutting and 50 - 60% less wastage."`,
      signature: ['Sandra Grocott', 'GM Operations', 'Arhgola Outdoor Shelters, NZ'],
      image_url: '/assets/homepage/testimonials/testimonial_sandragrocott@600w.png'
    }, {
      title: 'High accuracy + Simple to use',
      description: `"The boys love it and things are now always ut accurately. It has improved our job satisfaction all round."`,
      signature: ['Shannon McNab', 'Director', 'Davimac Machinery, NSW'],
      image_url: '/assets/homepage/testimonials/testimonial_shannonmcnab@600w.png'
    }] as ITestimonial[]);

    this.productButtons.push(...[{
      title: 'Measures & Length Stops',
      description: 'Explore our range of simple industrial saw-stops.\nHigh speed, high accuracy cutting production\ncut, after cut, after cut.',
      url: '#',
      image_url: '/assets/homepage/products/measureslengthstops@600w.png'
    }, {
      title: 'Fully Automatic Saws',
      description: 'Find a saw suitable for you.\nSimple automatic saws for cutting timber, steel and aluminium.\nHigh-productiviy cutting solutions for small-medium profiles.',
      url: '#',
      image_url: '/assets/homepage/products/fullyautomaticsaws@600w.png'
    }, {
      title: 'Semi Automatic Saws',
      description: 'Find a saw suitable for you.\nSemi automatic saws for cutting timber, steel and aluminium.\nHigh-productivity cutting solutions for small-medium profiles.',
      url: '#',
      image_url: '/assets/homepage/products/semiautomaticsaws@600w.png'
    }, {
      title: 'Special Projects',
      description: 'Special-purpose automation and software solutions.\nAutomated production applications design and development.\nHigh-efficiency manufacturing from order to finished products.',
      url: '#',
      image_url: '/assets/homepage/products/specialprojects@600w.png'
    }] as IProductButton[]);
  }

  ngOnInit(): void {
  }

}
