<div class="testimonial-wrapper" [class.reverse]="reverse">
  <div
    style="width: 300px; height: 230px; display: flex; align-items: center; margin: 8px; justify-content: center; overflow: hidden;">
    <img src="{{ source.image_url }}" style="width: 100%;">
  </div>
  <div>
    <h3 style="text-transform: uppercase; font-weight: 600; border-bottom: 1px solid black;">{{ source.title }}</h3>
    <p>{{ source.description }}</p>
    <p>
      <span *ngFor="let signature of source.signature" style="display: block;">
        {{ signature }}
      </span>
    </p>
  </div>
</div>
