import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { eProductGroup, eProductType } from 'src/services/models/enums';
import { ContactComponent } from './contact/contact.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ProductComponent } from './product/product.component';
import { ProductivityFocusComponent } from './productivity-focus/productivity-focus.component';
import { ProductsMainComponent } from './products-main/products-main.component';
import { ProductsComponent } from './products/products.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { WhyProfistopComponent } from './why-profistop/why-profistop.component';


const routes: Routes = [
  { path: 'products-list-measuring-stop-automatic-saw', component: ProductsMainComponent },
  { path: 'measures-and-length-stops', component: ProductsComponent, data: { type: eProductGroup.measuresAndLengthStops } },
  { path: 'fully-automatics-saws', component: ProductsComponent, data: { type: eProductGroup.fullyAutomaticsSaws } },
  { path: 'zero-measuring-stop', component: ProductComponent, data: { type: eProductType.zero } },
  { path: 'lazer-measuring-stop', component: ProductComponent, data: { type: eProductType.lazer } },
  { path: 'automatic-saw', component: ProductComponent, data: { type: eProductType.alpha } },
  { path: 'omicron', component: ProductComponent, data: { type: eProductType.omicron } },
  { path: 'aluminium-automatic-saws', component: ProductComponent, data: { type: eProductType.aluminium } },
  { path: 'steel-automatic-saws', component: ProductComponent, data: { type: eProductType.steel } },
  { path: 'timber-automatic-saws', component: ProductComponent, data: { type: eProductType.timber } },
  { path: 'automatic-saw-loading', component: ProductComponent, data: { type: eProductType.cuttingLine } },
  { path: 'special-projects', component: ProductComponent, data: { type: eProductType.special } },
  { path: 'why-profistop', component: WhyProfistopComponent },
  { path: 'who-we-are', component: WhoWeAreComponent },
  { path: 'productivity-optimisation-focus', component: ProductivityFocusComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'aluminium-automatic-saws', component: ProductComponent },
  { path: '', component: HomepageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
