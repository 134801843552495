<div [ngClass]="{'wrapper': !source.bestSeller, 'wrapper-focused': source.bestSeller}">
  <div *ngIf="source.bestSeller" class="best-seller-wrapper">
    <div class="best-seller">
      <div>Best seller</div>
      <div>
        <div
          style="display: grid; grid-template-columns: repeat(5, 1fr); align-items: center; justify-content: center; fill: #ffbb00; padding: 0 16px; box-sizing: border-box;">
          <svg *ngFor="let i of [].constructor(5)" xmlns="http://www.w3.org/2000/svg" width="10" height="10"
            viewBox="0 0 24 24">
            <path
              d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <h4 class="title">{{ source.title }}</h4>
  <div style="height: 175px;overflow: hidden;display: flex;align-items: center;">
    <img src="{{ source.imageUrl }}" style="width: 100%;" />
  </div>
  <p class="subtitle">{{ source.subtitle }}</p>
  <p class="description">{{ source.description }}</p>
  <div class="materials">
    <ul
      style="list-style-type: none; margin: 1.5em 0; padding: 0; display: flex; align-items: center; justify-content: center;">
      <li *ngFor="let material of source.supportedMaterials" [class.available-for-material]="material.supported"
        [class.not-available-for-material]="!material.supported"
        style="margin: 0 10px; font-size: 11px; position: relative;">{{ material.material }}</li>
    </ul>
  </div>
  <p style="font-size: 12px; margin: 2em;">Prices start from {{ source.price }}</p>
  <div style="flex: 1 1 auto"></div>
  <button class="view" [routerLink]="source.url"
    style="margin: 1.5em auto; display: flex; text-transform: uppercase; padding: 5px 9px; font-size: 15px; background: #fff; border: none;">View
    full specs</button>
</div>
