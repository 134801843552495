import { Component, OnInit } from '@angular/core';
import { IContact } from 'src/services/models/contact';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {

  public contacts: IContact[] = [];

  constructor() {
    this.contacts.push(...[
      {
        country: 'AUSTRALIA',
        company: 'PROFIFEED TECHONOLOGIES',
        phone: '1300 776 700',
        email: 'info@profistop.com',
        address: '47 Healey Road, Dandenong\nSouth, VIC 3175, Australia',
        imageUrl: '/assets/contacts/countries/australia.png',
        mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3142.8308801867192!2d145.22159731509237!3d-38.02772197971424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6136b33409a5b%3A0x7efa87bc89034406!2sProfiFeed%20Technologies%20Pty%20Ltd!5e0!3m2!1sen!2sde!4v1640917748916!5m2!1sen!2sde'
      } as IContact,
      {
        country: "EUROPE",
        company: 'PROFIFEED TECHONOLOGIES',
        phone: '381 37 202722',
        email: 'info@profistop.com',
        address: 'Majke Jugovica 25A, Kruševac\n37000 Serbia',
        imageUrl: '/assets/contacts/countries/europe.png',
        mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2890.2330394680143!2d21.322810415281502!3d43.58086207912374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4756873a40136731%3A0xcaa923808c081c55!2sProfiFeed%20Technologies%20Europe!5e0!3m2!1sen!2sde!4v1640917695861!5m2!1sen!2sde'
      } as IContact,
      {
        country: 'NORTH AMERICA',
        company: 'PROFIFEED TECHONOLOGIES',
        phone: '+1 819 367 2101',
        email: 'info@profistop.com',
        address: '1200 Rue Principale, Daveluyville\nQC G0Z 1C0, Canada',
        imageUrl: '/assets/contacts/countries/north-america.png',
        mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2763.347515470256!2d-72.13921358462348!3d46.16373757911542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc7fb845c073a15%3A0xe6ef7f6c3275ce78!2sProfiFeed%20Technologies%20North%20America!5e0!3m2!1sen!2sde!4v1640918028140!5m2!1sen!2sde'
      } as IContact,
      {
        country: 'UNITED KINGDOM',
      company: 'PROFIFEED TECHONOLOGIES',
      phone: '0151 548 9000',
      email: 'info@profistop.com',
      address: 'Moorgate Road, Konwsley\nIndustrial Park KIRKBY, LIVERPOOL\nL33 7DR United Kingdom',
      imageUrl: '/assets/contacts/countries/united-kingdom.png',
      mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2375.02011058557!2d-2.8724835843327234!3d53.468100680004724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b1913341bbc29%3A0x50b28de639927904!2sProfiFeed%20Technologies%20UNITED%20KINGDOM!5e0!3m2!1sen!2sde!4v1640917973915!5m2!1sen!2sde'
    } as IContact
    ])
  }

  ngOnInit(): void {
  }

}
