import { Component, OnInit } from '@angular/core';
import { IProfiStopFeatures } from 'src/services/models/profistop-features';

@Component({
  selector: 'app-productivity-focus',
  templateUrl: './productivity-focus.component.html',
  styleUrls: ['./productivity-focus.component.sass']
})
export class ProductivityFocusComponent implements OnInit {

  public didYouKnow: any = {
    title: 'Did you know!',
    description: 'You can cut optimized cut lists on all ProfiStop® machines.\nIn addition to material waste savings, optmization results\nin improved productivity as you hanle less material stock\nand make less trim cuts.'
  }

  public productivityFocus: IProfiStopFeatures[] = [];

  constructor() {
    this.productivityFocus.push(...[
      {
        title: 'On-Screen Optimisation',
        description: 'Get the benefit of yield-maximised cut lists\nwithout spending your time managing stock. Send the\nsame ut list you use today to ProfiStop®. Your operator\nselects material input, ProfiStop® optimises the cut list.\nMaterial waste reduced, without thinking about it.',
        imageUrl: '/assets/productivity-focus/productivity_illu1.svg'
      } as IProfiStopFeatures,{
        title: 'Adhesive Labels and Bar-Code printing',
        description: 'Sort out your many parts by labelling them.\nProfiStop® can print job or part information labels\nautomatically for every cut made including bar codes.\nEspoecially effective for yield-optimised job lists or\nlarge production volume cutting.',
        imageUrl: '/assets/productivity-focus/productivity_illu2.svg'
      } as IProfiStopFeatures,{
        title: 'OptiCut-1D™',
        description: 'Our cloud-based designs, job and material manage-\nment solution seamlessly integrated with ProfiFeed\nCutting Line. Automatically group and optimize your\nmany daily jobs and send to the machine with a\nclick of the mouse.',
        imageUrl: '/assets/productivity-focus/productivity_illu3.svg'
      } as IProfiStopFeatures
    ])
  }

  ngOnInit(): void {
  }

}
