import { Component, Input, OnInit } from '@angular/core';
import { IProductDetailedButton } from 'src/services/models/product-detailed-button';

@Component({
  selector: 'app-product-detailed-button',
  templateUrl: './product-detailed-button.component.html',
  styleUrls: ['./product-detailed-button.component.sass']
})
export class ProductDetailedButtonComponent implements OnInit {

  @Input('source') source: IProductDetailedButton = null;

  constructor() { }

  ngOnInit(): void {
    if (this.source == null) {
      this.source = { title: '', subtitle: '', description: '', supportedMaterials: [], price: '', url: '#', imageUrl: ''};
    }
  }

}
