<div class="content-header">
  <div class="content-header-static">
    <div class="content-header-static-mask">
      <div class="content-header-mask-gradient"></div>
    </div>
    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; max-width: calc(100% - 220px); display: flex;">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="content-header-dynamic">
    <div class="content-header-dynamic-mask">
      <div class="content-header-dynamic-content" style="background-position: top center;
      background-repeat: no-repeat; background-size: cover" [ngStyle]="{'background-image':'url(' + imageUrl + ')'}"  >
      </div>
    </div>
  </div>
</div>
