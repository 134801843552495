<div style="background-color: #fff; flex: 1 1 auto">
  <div class="inner-content">
    <nav class="menu-1">
      <ul>
        <li><a href="#">Careers</a></li>
        <li><a href="/who-we-are">Who We Are</a></li>
        <li><a href="/contact">Contact Us</a></li>
        <li style="display: flex; align-items: center; justify-content: center;">
          <img src="/assets/flags/au.svg" style="margin-right: 4px; width: 16px; height: 12px;" />
          <span>English</span>
        </li>
      </ul>
    </nav>
    <nav class="menu-2">
      <ul>
        <li><a routerLink="/products-list-measuring-stop-automatic-saw">Products</a></li>
        <li><a href="/productivity-optimisation-focus">Productivity Focus</a></li>
        <li><a href="#">OptiCut-1D</a></li>
        <li><a href="/why-profistop">Why ProfiStop?</a></li>
        <li><a href="#">News</a></li>
      </ul>
    </nav>
  </div>
</div>
