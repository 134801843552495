<div class="carousel">
  <div class="carousel-static">
    <div class="carousel-static-mask">
      <div class="carousel-mask-gradient"></div>
      <img src="/assets/default/pslogo.svg"
        style="width: 311.5px; height: 86.8px; position: absolute; top: calc(50% - 72px); left: calc(50% - 225px);" />
    </div>
  </div>
  <div class="carousel-dynamic">
    <div class="carousel-dynamic-mask">
      <div class="carousel-dynamic-content" style="background:url(assets/homepage/slideshow_1@1174w-80.jpg); background-position: center center;
      background-repeat: no-repeat; background-size: cover">
      </div>
    </div>
  </div>
</div>
<div class="carousel-text">
  <h3>Simple Automatic Saws for Aluminium Steel and Timber</h3>
  <p>ProfiStop is a range of automatic saw and measuring stop system suitable for transforming<br>
    saws into simple to use, high-speed, high producitvity workstations
  </p>
  <p>We supercharge your cutting productivity by hundreds of percent as well as streamline all your<br>
    production management from order to finished parts
  </p>
</div>

<div style="position: relative;">
<img src="/assets/default/download-brochure@500w.png" style="width: 250px; position: absolute; left: calc(50% - 125px); top: -35px" />
</div>

<div class="content"
  style="flex: 1 1 auto; background:url(assets/homepage/homepage-background.jpg); background-repeat: no-repeat; background-position: top center; background-size: cover">
  <div class="inner-content" style="position: relative;margin: 32px auto; margin-top: 64px;; max-width: 780px;">
    <ng-container *ngFor="let testimonial of testimonials; let i = index">
      <app-testimonial [source]="testimonial" [reverse]="(i + 1) % 2 == 0"></app-testimonial>
    </ng-container>
  </div>

  <div style="width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <h3 style="text-transform: uppercase;">Productivity in focus - Case study
    </h3>
    <img src="/assets/homepage/casestudy@900w.png" style="width: 600px;" />
  </div>

  <div style="display: grid;grid-template-columns: repeat(2,1fr);max-width: 1000px;margin: 64px auto;justify-content: center; align-items: center; column-gap: 24px; row-gap: 24px; margin-bottom: 96px;">
    <ng-container *ngFor="let productButton of productButtons">
      <app-product-button [source]="productButton"></app-product-button>
    </ng-container>
  </div>
</div>
