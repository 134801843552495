import { Component, Input, OnInit } from '@angular/core';
import { ITestimonial } from 'src/services/models/testimonial';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.sass']
})
export class TestimonialComponent implements OnInit {

  @Input('source') source: ITestimonial;
  @Input('reverse') reverse: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (this.source == null) {
      this.source = { title: '', description: '', signature: [], image_url: '' } as ITestimonial;
    }
  }

}
