<div style="height: 250px; position: relative;">
  <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image:url(/assets/who-we-are/whoweare-banner.jpg); background-size: cover;"></div>
  <div style="width: 100%; max-width: 780px; margin: 0 auto; position: relative; height: 100%;">
    <img src="/assets/default/download-brochure@500w.png" style="width: 250px; position: absolute; bottom: 12px; right: -125px;" />
  </div>
</div>

<div style="position: relative;">
  <div
    style="background-image: url(/assets/why-profistop/why-profistop-background.jpg); background-size: cover; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; filter: opacity(.15)">
  </div>


  <div
    style="padding-top: 48px; max-width: 780px; display: grid; grid-template-columns: repeat(2, 1fr); column-gap: 64px; margin: 0 auto;">
    <img src="/assets/who-we-are/profifeed-techonologies-logo.svg" style="width: 380px; max-width: 100%;">
    <img src="/assets/who-we-are/bojantrabonjaca@760w.png" style="width: 380px; max-width: 100%;">
  </div>

  <div style="max-width: 780px; text-align: center; margin: 72px auto;">
    <p>
      ProfiFeed Technologies was founded in 2006 by Mr Bo Trabonjaca.
    </p>
    <p>
      Mr Trabonjaca, an Aerospace Control Systems Engineer, left a corporate career to start a business out of his
      parents
      garage with a vision to design simple but highly functional industrial products.
    </p>
    <p>
      ProfiStop products are a result of many automation solutions during which time we observed that most manufacturers
      in
      the 21st Century cut the same way as at the dawn of circular saws – relying on their worker’s backs, primarily to
      entirely. ProfiStop products take your and your workers backs out of cutting, once and for all.
    </p>
    <p>
      We operate under the belief that in order to survive and grow we must set for ourselves the pass bar at having the
      best products in the World. Additionally we define our success in terms of whether you, our Customers, think we
      have
      achieved this goal.
    </p>
    <p>
      Our commitment to you will always be to provide you the best products in your field and nothing less.
    </p>
  </div>


  <div style="width: 1024px; max-width: 100%; margin: 128px auto; margin-bottom: 0; padding-bottom: 168px;">
    <div
      style="display: grid; grid-template-columns: repeat(6, 1fr); align-items: center; justify-content: center; font-size: 13px; text-align: center; margin-bottom: 60px;">
      <ng-container *ngFor="let productTimeline of productsTimeline; let i = index">
        <img [attr.src]="productTimeline.imageUrl" style="max-width: 150px; justify-self: center;"
          [ngStyle]="{'order' : i + 1 }">
        <div
          style="position: relative; border-top: 1px solid #007cd5; padding-top: 6px; margin-top: 8px; font-weight: 600; margin-bottom: 8px;"
          [ngStyle]="{'order' : i + productsTimeline.length + 1 }">
          <span style="border-bottom: 1px solid black; padding-bottom: 4px;">Year: {{ productTimeline.year }}</span>
          <ng-container *ngIf="i + 1 == (productsTimeline.length / 2)">
            <div style="position: absolute; right: 0; top: 0; width: 1px; background-color: #007cd5; height: 70px;">
              <div
                style="position: absolute; bottom: 0;white-space: nowrap;transform: translateX(-50%) translateY(100%);">
                <span style="font-size: 15px; font-weight: 600; border-bottom: 1px solid #007cd5;">Products
                  timeline</span>
              </div>
            </div>
          </ng-container>
        </div>
        <div [ngStyle]="{'order' : i + 2*productsTimeline.length + 1 }">Product lines: {{ productTimeline.productLines
          }}
        </div>
      </ng-container>
    </div>
  </div>

</div>
