import { Component, OnInit } from '@angular/core';
import { IProductsGroupButton } from 'src/services/models/products-group-button';

@Component({
  selector: 'app-products-main',
  templateUrl: './products-main.component.html',
  styleUrls: ['./products-main.component.sass']
})
export class ProductsMainComponent implements OnInit {

  public productsGroups: IProductsGroupButton[] = [];

  public headerContent: { description?: string, signature?: string[] } = {};

  constructor() {
    this.headerContent.description = 'We have had our ProfiStop since 2015. From not having known\nabout this kind of autmation before to now after having one, I\ncan say I would not run a shop again without having one on a saw.\nIt is one of those things that makes a productive and stress-free\nbusiness compared to one where you are constantly doing chores."';
    this.headerContent.signature = ['Admir Bućan', 'Director', 'EA Probend VIC, AUSTRALIA'];

    this.productsGroups.push(...[
      {
        title: 'Measures & Length Stops',
        description: 'Explore our range of simple industrial saw-stops.\nHigh speed, high accuracy cutting production cut, after cut, after cut.',
        url: '/measures-and-length-stops',
        imageUrl: '/assets/products/measures-length-stops.png'
      } as IProductsGroupButton,
      {
        title: 'Fully Automatic Saws',
        description: 'Find a saw suitable for you.\nSimple automatic saws for cutting timber, steel and aluminium.\nHigh-productivity cutting solutions for small-medium profiles.',
        url: "/fully-automatics-saws",
        imageUrl: '/assets/products/fully-automatic-saws.png'
      } as IProductsGroupButton,
      {
        title: 'Semi Automatic Saws',
        description: 'Find a saw suitable for you.\nSemi automatic saws for cutting timber, steel and aluminium.\nHigh-productivity cutting solutions for small-medium profiles.',
        url: "#",
        imageUrl: '/assets/products/semi-automatic-saws.png'
      } as IProductsGroupButton,
      {
        title: 'Special Projects',
        description: 'Special-purpose automation and software solutions.\nAutomated production applications design and development.\nHigh-efficiency manufacturing from order to finished products.',
        url: "#",
        imageUrl: '/assets/products/special-projects.png'
      } as IProductsGroupButton
    ])
  }

  ngOnInit(): void {
  }

}
