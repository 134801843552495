import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.sass']
})
export class ContentHeaderComponent implements OnInit {

  @Input('imageUrl') imageUrl: string = null;

  constructor() { }

  ngOnInit(): void {
    if (this.imageUrl == null) {
      this.imageUrl = "";
    }
  }

}
