<footer style="flex: 1 1 auto">
  <div style="background-color: #3B3B3B;">
    <div style="max-width: 1068px; display: flex; margin: 0 auto;">
      <div style="flex: 1 1 auto;">
        <nav class="footer-menu">
          <ul>
            <li><a href="/products-list-measuring-stop-automatic-saw">Products</a></li>

            <li><a href="/productivity-optimisation-focus">Productivity Focus</a></li>

            <li><a href="#">OptiCut-1D</a></li>

            <li><a href="/why-profistop">Why ProfiStop?</a></li>

            <li><a href="#">News</a></li>

            <li><a href="#">Privacy policy</a></li>
          </ul>
        </nav>
        <p style="margin: 48px 0; max-width: 624px">ProfiStop® is a range of lenght stop and feeding system suitable for
          transformin yours saws into simple to
          use, automated, high-productivity workstations.</p>
        <p style="margin: 48px 0; max-width: 624px">In addition to mechanical automation, ProfiStop® streamlines your
          production management in the most intuitive
          ways possible, from the initial order to finished parts</p>
      </div>
      <div style="flex: 0 0 180px; font-size: 12px;">
        <h3 style="font-size: 16px; font-weight: 600; margin-top: 24px;">Profifeed Technologies</h3>
        <div style="display: grid; grid-template-columns: 20px auto;">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAApUlEQVQ4y82TTRHCMBCFX1AQCXUAOKiESKiEIqEKKiESkAAOioNKiISPA8uQyaHTtBz6Lvszs9+83UykwwoIQALGrYCRj6LFthbQ8NMdmIG4ZvaU5U2WB0nPorfazZy56YFNkDaDhD2vMxgkAZc9oPgv0JSBWut5q5M59pLkFiBe0kPS18nN4tliJ+nlnLvWrJYrWX+qWa23wVJd7Y18cfDheD//DSMe5MlZngDXAAAAAElFTkSuQmCC" />
          <span>1300 776 700</span>
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAfUlEQVQ4y+2T0Q1AQBBEH1GAUnSiE1ESHVABHVCCaIAOxs9KjsPHfnvJJbtze5PNJAc/d5KzkFQDpcOjDU3kXGRMnQ/3sEk/huYHfQG6u5i9mORmMgKFnQ5YgcruY3RlkzRZPUiqTTv7LZgdvMHuwTbuYPO3YBunYf9/t5gDyalSq9Rcw5cAAAAASUVORK5CYII=" />
          <span>info@profistop.com.au</span>
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAqklEQVQ4y82TYQ3CMBCFX1BQCZWAAyZhEioBJ0iohAUMTMIIBiZhOPj4s4ZL05WRhoT369pLv3vX3El/L6ADBt5agAj4vYDAthbguMeB1QD0BVeuBokWkOVGkzun+0OB05v4keXuJj7VnFjNyTbg1zaSxpoTW81LmtcHkyT7D88a5JadnaQuA0jStdaOy2yXNLXOCR/nxIAuG4Dw7ejHJkDBUWhdRv/zjX8BxQo45QhyZ1QAAAAASUVORK5CYII=" />
          <span>47 Healey Road, Dandenong South, VIC 3175, Australia</span>
        </div>
        <div style="display: flex; align-items: center; justify-content: center; margin: 8px 0;">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAYAAAAfrhY5AAAA/klEQVRIx+2XwW2DQBBF31gpgAoiSqAESnAJTgVRKohwA3YHcQeWK8BKBXRAOggdfF9AGq1IZOwFH7z/ghh297F/VjMAzyrzN5IK4B3IZ2R+m9knATiX9KtldARYOf4bkC3k+FpS9jLDwmfgBDQuVgC7YFwRG/5hZvswKGl08CoieD8G/k8x4dXUCbFsb8ysczZnwJc7wNmc8C48TMB6Sdsn66Hwe23f9tc2iP+4ZwCvwObPVSRVU2vktW8oqRyZXqac3yRJQ2FpzezgO2TfqHzOiZpzp/qKHKecJ3iCpwp3s8opjWb4APE7rxfcdGNmTVhiN5Lamf9W6r72P1YXix6zdQaptWIAAAAASUVORK5CYII="
            width="27" height="27">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAYAAAAfrhY5AAABHUlEQVRIx+2W0U3DQBBE31gUYDrAHZgGkNMB6QBXQAlRaCCUkBKABuwS3EHcQdzB8GPQycLBkeILEVnpPlYr6513Z7QH/zUUJrbXwMPMzA5YS2pC8JPjxR4gCW6URex4artIzjnzPw3fAgtgCTSnht8cqDWSykCQDbCL9eftDxaJ1vZH20WQb2K2HaCy3QJpf6Kr/e5IcDtVnIfgtYLoVf8Vi0GtBt6ATFIm6R64BV5i+Pxd0lLSt0gldZJWQDkrXNJrb8fCdjqobcfGcBK47bxfFhWwG7gE4GPOtq8CUabA89A1c8LTX/LLWyxX+BUebavltqsR+2xsh/s9P+LbSfAUKMYuNsH3xcXMfB+Z3SSDl2odAdoBpaTunF3nE07AvkWAAib2AAAAAElFTkSuQmCC"
            width="27" height="27">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAYAAAAfrhY5AAABlElEQVRIx+VXwW3CQBCcQXlHTgfQQAIdQAehA1wBpIGg0ECkNACpAHdgKxU4FUAHWGlg8shaOgwxxvY5j+zrbEs3t3M7O2vgvwbdB0lDAHMAfY+YHySXKAD3JR3UTWwBoOfghwCCjhh/lBTceAZZAYhtHQBYOwkOfYPHJBPnaucAxvlz7y+r3Td4sYaOVOSb9q2kxAFuFTwFkAEYlihl3DbtGwADkiOSE5J3AKYA9r7vPCQZkjwCIhkBGBkbXsAjkptfezWZGQNewF8umsUPI5sm4CtaFDauSulnga18r6QLnWclWq8HLqmq1T64FtoEPCk436UDBgBmzqtdE/B3Z/1sA0dZvDpUZwCi2uAmLVfTsaTZuYwlrQtZv5n8zkbV9jo1Xw5yX5a0tKy+ANxbG3WLKzkZl+qAk0wlTZwD5EaxKKmTaWtNxjQ+uNBA9tZ+J2V013I12zCU9GQ0j+zTwWhOr9mvlqXaIaKySm7SXm/bbnmm/0rDxELSwvOUk7mZxx3OjulJfUiaSdp5/luJr/AIf/EN/EBEnsWIGkwAAAAASUVORK5CYII="
            width="27" height="27">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAYAAAAfrhY5AAABG0lEQVRIx+2W7W2DMBiE71AGYIKIEcgGjEAmyAhpNqm6QLpByAaoCwRlgWSDeoPrH0ei1EjYGP8pJ1kIhPX4vfMXsGpVQtH1UVINYGdftwCKGYwv+/wG0JB8jv4p6axlVY+BKy2vx4uXDfiHBFEXkkoXvEo01/Yu+NSJ9bQtVNtf8JcVHvAdgCbU+mHludcaJQ3JPYBTaPnZ7I2CfLcu+MRQRYHbAXQhMWTRtsqAGLIFllE7NYKocElvAG5Tl+wmEjQHcAZQ+/TbRACXAC6eJ18323Zfm3syw8o7j865pIuvzaNwkkaSmbjTlbaF6u6yvU10qt1c8GsCsCHZ/IGT/PTMPkQfoxdISQWAYy/TYuYFsu1NsqstcNU/1Q/lLA/LJf31+AAAAABJRU5ErkJggg=="
            width="27" height="27">
        </div>
      </div>
    </div>
  </div>
  <div style="flex: 1 1 auto; background-color: #000000; padding: 24px 0">
    <div style="max-width: 1068px; margin: 0 auto; width: 100%; display: flex; align-items: center; justify-content: center;">
      <div style="flex: 1 1 auto">
        <p>Copright © Profistop 2016. All Rights Reserved. A Profifeed Technologies product.</p>
        <p>Visit mobile website: m.profistop.com.au</p>
      </div>
      <div style="flex: 0 0 auto"><img src="/assets/default/pslogo.svg" style="width: 180px;" /></div>
    </div>
  </div>
</footer>
