<div [ngStyle]="{'background-image': 'url(' + source.imageUrl + ')'}" style="background-size: contain; background-repeat: no-repeat; background-position: center center;">
  <div  style="position:relative; background: linear-gradient(270deg, rgba(4, 25, 44, .5), rgb(4, 25, 44)); color: #fff;; padding: 32px 64px; box-sizing: border-box;">
    <h3 style="font-size: 1.7em; margin-top: 16px;">{{ source.title }}</h3>
    <p style="white-space: pre-line; margin-bottom: 16px;;">{{ source.description }}</p>
    <a [attr.href]="source.url">
      <img [attr.src]="position % 2 == 0 ? '/assets/default/findoutmore.svg' : '/assets/default/findoutmore_gray.svg'"
        style="position: absolute;height: 90px;top: calc(50% - 45px);right: 32px;" />
    </a>
  </div>
</div>
