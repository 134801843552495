<app-content-header [imageUrl]="'/assets/products/measure-and-length-stops/measurelengthstops-banner.jpg'">
  <div style="color: #fff; align-self: center; margin: 0 auto; line-height: 1.3;">
    <p style="white-space: break-spaces; margin: 32px 0;">{{ headerContent.description }}</p>
    <p style="display: flex; flex-direction: column; font-style: italic; margin: 32px 0;">
      <span *ngFor="let signature of headerContent.signature">{{ signature }}</span>
    </p>
  </div>
</app-content-header>
<div style="background:url(assets/homepage/homepage-background.jpg); background-repeat: no-repeat; background-position: top center; background-size: cover">
  <div class="products-text">
    <h3>{{ bodyContent.title }}</h3>
    <p style="white-space: pre-line;">{{ bodyContent.description }}</p>
  </div>
  <div
    style="display: grid; grid-template-columns: repeat(4, minmax(auto, 300px)); row-gap: 16px; column-gap: 16px; align-items: center; justify-content: center; padding: 32px 16px; padding-bottom: 96px; box-sizing: border-box;">
    <div *ngFor="let button of productButtons" style="max-width: 300px; height: 100%; flex: 1 1 auto; display: flex;">
      <app-product-detailed-button [source]="button"></app-product-detailed-button>
    </div>
  </div>
</div>
<div class="content-footer"
  style="position: relative; display: flex; align-items: center; justify-content: center;background-image: url(/assets/products/footerviewbanner_900w.png);background-size: cover;background-position: center center;background-repeat: no-repeat;">
  <!-- <div style="background: linear-gradient(180deg, rgba(4, 25, 44, 0.7), #04192c);"></div> -->
  <div
    style="background-image: url(/assets/products/footerview_600w.png); background-position: left bottom; width: 350px; height: 275px; background-size: cover; background-repeat: no-repeat; clip-path: polygon(60px 0, 100% 0, calc(100% - 60px) 100%, 0 100%)">
  </div>
  <div
    style="display: flex; flex-direction: column; align-items: flex-start; padding: 0 16px; box-sizing: border-box;z-index: 1; color: #fff">
    <h3 style="font-size: 20px; margin: 6px 0">View Automatic Saws</h3>
    <p style="margin: 0; line-height: 1.7;">ProfiStop automatic saws are a simple solution<br>for high-speed
      cutting-to-length of aluminium profiles.</p>
    <button class="view-full-specs"
      style="position: relative; margin: 24px 0; flex: 0 0 auto; border: 2px solid #007cd4; border-radius: 5px 0 0 5px; background-color: #eaeaea; padding: 6px 12px; text-transform: uppercase; font-size: 13px; letter-spacing: -0.5px;">View
      Full Specs
    </button>
  </div>
</div>
