import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TestimonialComponent } from './homepage/elements/testimonial/testimonial.component';
import { ProductButtonComponent } from './homepage/elements/product-button/product-button.component';
import { ImageLoaderComponent } from './shared/image-loader/image-loader.component';
import { ContentHeaderComponent } from './shared/content-header/content-header.component';
import { ProductsComponent } from './products/products.component';
import { ProductDetailedButtonComponent } from './products/elements/product-detailed-button/product-detailed-button.component';
import { ProductComponent } from './product/product.component';
import { TranslationExistsPipe } from '../infrastructure/pipes/translation-exists.pipe';
import { SafePipe } from '../infrastructure/pipes/safe.pipe';
import { ProductsMainComponent } from './products-main/products-main.component';
import { ProductsButtonComponent } from './products-main/elements/products-button/products-button.component';
import { WhyProfistopComponent } from './why-profistop/why-profistop.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { ProductivityFocusComponent } from './productivity-focus/productivity-focus.component';
import { ContactComponent } from './contact/contact.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    TestimonialComponent,
    ProductButtonComponent,
    ImageLoaderComponent,
    ContentHeaderComponent,
    ProductsComponent,
    ProductDetailedButtonComponent,
    ProductComponent,
    TranslationExistsPipe,
    SafePipe,
    ProductsMainComponent,
    ProductsButtonComponent,
    WhyProfistopComponent,
    WhoWeAreComponent,
    ProductivityFocusComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
      },
      defaultLanguage: 'en'
  })
  ],
  providers: [TranslationExistsPipe, SafePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
