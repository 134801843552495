<div
  style="background-image: url(/assets/productivity-focus/productivityfocus-banner.jpg);background-position: center center;background-size: cover;background-repeat: no-repeat; padding: 32px 0;">
    <div
      style="margin: 0 auto; width: 780px; max-width: 100%; text-align: center; color: #fff; display: flex; flex-direction: column;">
      <img src="/assets/productivity-focus/bulb.svg" style="max-height: 64px; margin-bottom: 4px;">
      <strong style="font-size: 1.5em;">{{ didYouKnow.title }}</strong>
      <p style="white-space: pre-line; margin: 2em; margin-bottom: 0;">
        {{ didYouKnow.description }}
      </p>
    </div>
</div>

<div style="position: relative; line-height: 1.5;">
  <div
    style="background-image: url(/assets/why-profistop/why-profistop-background.jpg); background-size: cover; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1;">
  </div>
  <div>
    <div style="background-color: rgba(255,255,255,.8);">

      <h1 style="padding-top: 64px; margin: 0; text-align: center;">High - Productivity Focussed</h1>
      <div style="display: flex; align-items: center; justify-content: center; padding-bottom: 64px;">
        <img src="/assets/productivity-focus/high-productivity-focus.png" style="width: 100%; max-width: 780px;">
      </div>
    </div>
    <div style="background-color: rgba(3, 28, 52, .9); color: #fff; padding: 64px 0">
      <div
        style="width: 1024px; max-width: 100%; margin: 0 auto; text-align: center; display: grid; grid-template-columns: repeat(2, 1fr); align-items: flex-start; justify-content: center; row-gap: 80px;">
        <div *ngFor="let feature of productivityFocus" class="productivity-focus-features"
          style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
          <div style="height: 60px; width: 100%; margin-bottom: 24px;">
            <img [attr.src]="feature.imageUrl" style="max-height: 60px; max-width: 78px;" />
          </div>
          <h3 style="margin: 0; line-height: 1.3; font-size: 15px; margin-bottom: 14px">{{ feature.title }}</h3>
          <p style="white-space: pre-line; text-align: center; margin: 0; line-height: 1.3;">{{ feature.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
