<div style="height: 250px; position: relative;">
  <div style="background: linear-gradient(90deg, rgba(4, 25, 44, .8), rgb(4, 25, 44)); height: 100%;">
    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-size: cover;"></div>
    <div style="width: 100%; max-width: 1024px; margin: 0 auto; position: relative; height: 100%; display: flex; align-items: center; justify-content: center;">
      <img src="/assets/default/download-brochure@500w.png"
        style="width: 250px; position: absolute; bottom: 12px; right: -125px;" />
        <img src="/assets/contacts/profistop-world-wide.svg" style="width: 100%; max-width: 450px;">
    </div>
  </div>
</div>
<div style="position: relative; padding: 100px 0;">
  <div
    style="background-image: url(/assets/why-profistop/why-profistop-background.jpg); background-size: cover; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; filter: opacity(.15);">
  </div>"
  <div
    style="display: grid; grid-template-columns: repeat(4, 1fr); width: 100%; max-width: 1024px; margin: 0 auto; column-gap: 24px;">
    <ng-container *ngFor="let contact of contacts; let i = index">
      <h3 style="margin: 0; font-size: 1.3em;" [ngStyle]="{'order': i + 1}">{{ contact.country }} </h3>
      <h4 style="margin: 0;" [ngStyle]="{'order': i + 5}">{{ contact.company }} </h4>

      <div style="display: grid; grid-template-columns: 40px auto; margin: 32px 0" [ngStyle]="{'order': i + 9}">
        <img style="filter: brightness(0);"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAApUlEQVQ4y82TTRHCMBCFX1AQCXUAOKiESKiEIqEKKiESkAAOioNKiISPA8uQyaHTtBz6Lvszs9+83UykwwoIQALGrYCRj6LFthbQ8NMdmIG4ZvaU5U2WB0nPorfazZy56YFNkDaDhD2vMxgkAZc9oPgv0JSBWut5q5M59pLkFiBe0kPS18nN4tliJ+nlnLvWrJYrWX+qWa23wVJd7Y18cfDheD//DSMe5MlZngDXAAAAAElFTkSuQmCC" />
        <span>{{ contact.phone }}</span>
        <img style="filter: brightness(0);"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAfUlEQVQ4y+2T0Q1AQBBEH1GAUnSiE1ESHVABHVCCaIAOxs9KjsPHfnvJJbtze5PNJAc/d5KzkFQDpcOjDU3kXGRMnQ/3sEk/huYHfQG6u5i9mORmMgKFnQ5YgcruY3RlkzRZPUiqTTv7LZgdvMHuwTbuYPO3YBunYf9/t5gDyalSq9Rcw5cAAAAASUVORK5CYII=" />
        <span>{{ contact.email }}</span>
        <img style="filter: brightness(0);"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAqklEQVQ4y82TYQ3CMBCFX1BQCZWAAyZhEioBJ0iohAUMTMIIBiZhOPj4s4ZL05WRhoT369pLv3vX3El/L6ADBt5agAj4vYDAthbguMeB1QD0BVeuBokWkOVGkzun+0OB05v4keXuJj7VnFjNyTbg1zaSxpoTW81LmtcHkyT7D88a5JadnaQuA0jStdaOy2yXNLXOCR/nxIAuG4Dw7ejHJkDBUWhdRv/zjX8BxQo45QhyZ1QAAAAASUVORK5CYII=" />
        <span style="white-space: pre-line;">{{ contact.address }}</span>
      </div>

      <img [ngStyle]="{'order': i + 13}" [attr.src]="contact.imageUrl" style="width: 100%; max-width: 250px;">
      <div [ngStyle]="{'order': i + 17}">
        <iframe [attr.src]="contact.mapUrl | safe" height="200" style="border:0;" allowfullscreen=""
          loading="lazy"></iframe>
      </div>
    </ng-container>
  </div>
</div>
