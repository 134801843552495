import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { eProductGroup } from 'src/services/models/enums';
import { IProductDetailedButton } from 'src/services/models/product-detailed-button';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.sass']
})
export class ProductsComponent implements OnInit {

  @Input('type') type: string = null;

  private allowedTypes: string[] = [
    eProductGroup[eProductGroup.measuresAndLengthStops].toUpperCase(),
    eProductGroup[eProductGroup.fullyAutomaticsSaws].toUpperCase(),
  ];

  private headerContentCollection: any = {};
  private bodyContentCollection: any = {};
  private productButtonsCollection: any = {};

  public headerContent: { description?: string, signature?: string[] } = {};
  public bodyContent: { title?: string, description?: string } = {};
  public productButtons: IProductDetailedButton[] = [];

  constructor(private router: Router, private route: ActivatedRoute) {
    this.type = eProductGroup[route.snapshot.data['type']]?.toUpperCase();

    this.headerContentCollection[eProductGroup[eProductGroup.measuresAndLengthStops].toUpperCase()] = {
      description: `"We have had a ProfiStop for a couple of years now.
Introducing this technology has enabled us to increase
production, free up resources and optimise raw material
to reduce waste. It was well worth the investment."`.replace(/\t/g, ''),
      signature: ['Sandra Grocott', 'General Manager', 'Arhgola Outdoor Shelter NZ']
    }
    this.headerContentCollection[eProductGroup[eProductGroup.fullyAutomaticsSaws].toUpperCase()] = {
      description: `Installing a Profistop system has given our company the flexibility to
offer our customer a precision cut product in a range of sizes and
grades. Since installing our saw early this year we have cut approx
500,000lm of precision cut timber.`.replace(/\t/g, ''),
      signature: ['Will Truscott', 'Operations Manager', 'True Blue Timber NSW', 'AUSTRALIA']
    }

    this.bodyContentCollection[eProductGroup[eProductGroup.measuresAndLengthStops].toUpperCase()] = {
      title: 'ProfiStop Measures & Length Stops',
      description: 'Measuring stop systems suitable for transforming your saws into\n simple to use, automated, high-productivity workstations'
    }
    this.bodyContentCollection[eProductGroup[eProductGroup.fullyAutomaticsSaws].toUpperCase()] = {
      title: 'ProfiStop Fully Automatics Saws',
      description: 'ProfiStop automatic saws are a simple solution\nfor high-speed cutting-to-length of aluminium profiles.'
    }

    this.productButtonsCollection[eProductGroup[eProductGroup.measuresAndLengthStops].toUpperCase()] = this._generateMeasuresAndLengthStopsButtons();
    this.productButtonsCollection[eProductGroup[eProductGroup.fullyAutomaticsSaws].toUpperCase()] = this._generateFullyAutomaticSawsButtons();
  }

  ngOnInit(): void {
    if (this.type == null || !this.allowedTypes.some(value => value === this.type)) {
      this.router.navigate(['/']);
    }

    this.headerContent = this.headerContentCollection[this.type];
    this.bodyContent = this.bodyContentCollection[this.type];
    this.productButtons = this.productButtonsCollection[this.type];
  }

  private _generateMeasuresAndLengthStopsButtons() {
       // ProfiStop Zero
       let measuresAndLengthStopsCollection = [];
       measuresAndLengthStopsCollection.push({
         title: 'ProfiStop Zero',
         subtitle: 'Best for Light Weight Materials',
         description: 'A simple, light-duty measuring stop for saws.\nIdeal for aluminium or softwood.\nBasic go-to-position operation.',
         supportedMaterials: [
           { material: 'Timber', supported: true},
           { material: 'Steel', supported: false},
           { material: 'Aluminium', supported: true}
         ],
         price: '$XX',
         url: '/zero-measuring-stop',
         imageUrl: '/assets/products/zero.png'
       } as IProductDetailedButton);
       // ProfiStop Lazer
       measuresAndLengthStopsCollection.push({
         title: 'ProfiStop Lazer',
         subtitle: 'Best for Light Weight Materials',
         description: 'A simple, light-duty measuring stop for saws.\nIdeal for aluminium or softwood.\nBasic go-to-position operation.',
         supportedMaterials: [
           { material: 'Timber', supported: true},
           { material: 'Steel', supported: false},
           { material: 'Aluminium', supported: true}
         ],
         price: '$XX',
         url: '/lazer-measuring-stop',
         imageUrl: '/assets/products/lazer.png'
       } as IProductDetailedButton);
       // ProfiStop Alpha
       measuresAndLengthStopsCollection.push({
         title: 'ProfiStop Alpha',
         subtitle: 'Best for Medium Weight Materials',
         description: 'Simple industrial auto-stop\nfor steel and solid timber.\nBasic go-to-position operation.\nLifetime accurancy guarantee.',
         supportedMaterials: [
           { material: 'Timber', supported: true},
           { material: 'Steel', supported: true},
           { material: 'Aluminium', supported: true}
         ],
         price: '$XX',
         url: '/automatic-saw',
         imageUrl: '/assets/products/alpha.png',
         bestSeller: true
       } as IProductDetailedButton)
       // ProfiStop Omicron
       measuresAndLengthStopsCollection.push({
         title: 'ProfiStop Omicron',
         subtitle: 'Best for Heavy Weight Materials',
         description: 'Simple auto-stop for heavy steel and timber.\nBasic go-to-position operation.\nLifetime accurancy guarantee.',
         supportedMaterials: [
           { material: 'Timber', supported: true},
           { material: 'Steel', supported: true},
           { material: 'Aluminium', supported: false}
         ],
         price: '$XX',
         url: '/omicron',
         imageUrl: '/assets/products/omicron.png'
       } as IProductDetailedButton);

       return measuresAndLengthStopsCollection;
  }

  private _generateFullyAutomaticSawsButtons() {
    // ProfiStop Zero
    let fullyAutomaticsSawsCollection = [];
    fullyAutomaticsSawsCollection.push({
      title: 'Automatic Saws for Aluminium',
      subtitle: 'Best for Light Weight Materials',
      description: 'A simple, light-duty measuring stop for saws.\nIdeal for aluminium or softwood.\nBasic go-to-position operation.',
      supportedMaterials: [
        { material: 'Timber', supported: false},
        { material: 'Steel', supported: false},
        { material: 'Aluminium', supported: true}
      ],
      price: '$XX',
      url: '/aluminium-automatic-saws',
      imageUrl: '/assets/products/aluminium.png'
    } as IProductDetailedButton);
    // ProfiStop Lazer
    fullyAutomaticsSawsCollection.push({
      title: 'Automatic Saws for Steel',
      subtitle: 'Best for Heavy Weight Materials',
      description: 'A simple, light-duty measuring stop for saws.\nIdeal for aluminium or softwood.\nBasic go-to-position operation.',
      supportedMaterials: [
        { material: 'Timber', supported: false},
        { material: 'Steel', supported: true},
        { material: 'Aluminium', supported: true}
      ],
      price: '$XX',
      url: '/steel-automatic-saws',
      imageUrl: '/assets/products/steel.png'
    } as IProductDetailedButton);
    // ProfiStop Alpha
    fullyAutomaticsSawsCollection.push({
      title: 'Automatic Saws for Timber',
      subtitle: 'Best for Light Weight Materials',
      description: 'Simple auto-stop for heavy steel and timber.\nBasic go-to-position operation.\nLifetime accurancy guarantee.',
      supportedMaterials: [
        { material: 'Timber', supported: true},
        { material: 'Steel', supported: false},
        { material: 'Aluminium', supported: false}
      ],
      price: '$XX',
      url: '/timber-automatic-saws',
      imageUrl: '/assets/products/timber.png'
    } as IProductDetailedButton)
    // ProfiStop Omicron
    fullyAutomaticsSawsCollection.push({
      title: 'Automatic Cutting Lines',
      subtitle: 'Best for Heavy Weight Materials',
      description: 'Simple auto-stop for heavy steel and timber.\nBasic go-to-position operation.\nLifetime accurancy guarantee.',
      supportedMaterials: [
        { material: 'Timber', supported: false},
        { material: 'Steel', supported: true},
        { material: 'Aluminium', supported: false}
      ],
      price: '$XX',
      url: '/automatic-saw-loading',
      imageUrl: '/assets/products/cutting-lines.png'
    } as IProductDetailedButton);

    return fullyAutomaticsSawsCollection;
}

}
