<app-content-header [imageUrl]="'/assets/products/header-products@900w.png'">
  <div style="color: #fff; align-self: center; margin: 0 auto; line-height: 1.3;">
    <p style="white-space: break-spaces; margin: 32px 0;">{{ headerContent.description }}</p>
    <p style="display: flex; flex-direction: column; font-style: italic; margin: 32px 0;">
      <span *ngFor="let signature of headerContent.signature">{{ signature }}</span>
    </p>
  </div>
</app-content-header>
<div
  style="background:url(assets/homepage/homepage-background.jpg); background-repeat: no-repeat; background-position: top center; background-size: cover">
  <div style="margin: 0 auto; display: flex;flex-direction: column;max-width: 100%;width: 780px;align-items: center;">
    <h3 style="font-size: 1.7em; margin: 0; margin-top: 42px;">Our products</h3>
    <h4 style="font-weight: normal; margin: 12px 0">Machinery, tables, software & accessories to give you a competitive
      edge.</h4>
    <div style="display: flex; flex-direction: column; row-gap: 20px; width: 100%; margin: 24px 0 64px 0;">
      <app-products-button *ngFor="let productsGroup of productsGroups; let i = index" [source]="productsGroup" [position]="i"></app-products-button>
    </div>
  </div>
</div>
