<div class="product-button-gradient-layer">
  <img src="{{ source.image_url }}" />
</div>
<div class="product-button-wrapper">
  <a class="find-out-more" href="{{ source.title }}">
    <img src="/assets/default/findoutmore.svg" />
  </a>
  <h3>{{ source.title }}</h3>
  <p>{{ source.description }}</p>
</div>
