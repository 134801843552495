import { Component, OnInit } from '@angular/core';
import { IProfiStopFeatures } from 'src/services/models/profistop-features';

@Component({
  selector: 'app-why-profistop',
  templateUrl: './why-profistop.component.html',
  styleUrls: ['./why-profistop.component.sass']
})
export class WhyProfistopComponent implements OnInit {

  public features1: IProfiStopFeatures[] = [];
  public features2: IProfiStopFeatures[] = [];

  public didYouKnow: any = {
    title: 'Did you know!',
    description: 'You can cut optimized cut lists on all ProfiStop® machines.\nIn addition to material waste savings, optmization results\nin improved productivity as you hanle less material stock\nand make less trim cuts.'
  }

  constructor() {
    this.features1.push(...[
      {
        title: 'Exceptional ROI',
        description: 'Most users find that their new ProfiStop® pays\nfor itself in under a year.',
        imageUrl: '/assets/why-profistop/wps_illu1.svg'
      } as IProfiStopFeatures,
      {
        title: 'Lifetime accuracy guarantee',
        description: 'We can onsite, at our factory or remotely\nrecalibrate your machine to ±0.1 mm\naccuracy for as long as you have it.',
        imageUrl: '/assets/why-profistop/wps_illu2.svg'
      } as IProfiStopFeatures,
      {
        title: 'Fast and accurate, every time',
        description: 'ProfiStop® quickly positions itself to\nyour length input and produces\nthe cuts you need, every time.',
        imageUrl: '/assets/why-profistop/wps_illu3.svg'
      } as IProfiStopFeatures,
      {
        title: 'Don\'t waste time entering data',
        description: 'Send your part lists to ProfiStop® straight from\nthe office. They will be there for your operator\nright away. Just press GO and cut.',
        imageUrl: '/assets/why-profistop/wps_illu4.svg'
      } as IProfiStopFeatures,
      {
        title: 'Eliminate tiresome mistakes',
        description: 'Let ProfiStop® take care of mundane\nmeasuring and counting. When one job is\ncomplete, ProfiStop® will tell your operator to go to the next job,\nand what stock to fetch.',
        imageUrl: '/assets/why-profistop/wps_illu5.svg'
      } as IProfiStopFeatures,
      {
        title: 'Focus on the big picutre',
        description: 'Free your operator from the mundane\nproces detail of cutting and their mind\nwill be fresh to make you money\nin more important ways.',
        imageUrl: '/assets/why-profistop/wps_illu6.svg'
      } as IProfiStopFeatures,
      {
        title: 'Get more done, with less',
        description: 'ProfiStop® super-charges your evrey\nworker, and makes a novice produce\nthe same as your best.',
        imageUrl: '/assets/why-profistop/wps_illu7.svg'
      } as IProfiStopFeatures,
      {
        title: 'Get something from nothing',
        description: 'ProfiStop® saves you $$ with optimised cut\nlists but without having to spend time\nmanaging stock. Don\'t throw away your\nmaterial, or time.',
        imageUrl: '/assets/why-profistop/wps_illu8.svg'
      } as IProfiStopFeatures,
      {
        title: 'Australian made and owned',
        description: 'ProfiStop® is a Worl-leading Australian\ntechnology product. Be a part of our story.',
        imageUrl: '/assets/why-profistop/wps_illu9.svg'
      } as IProfiStopFeatures,
    ]);

    this.features2.push(...[
      {
        title: 'Are you so busy that you can not\ntake any more work?',
        description: 'Every month you are likely losing many\ntimes more in sales and profit than the cost\nof investment in ProfiStop®.',
        imageUrl: '/assets/why-profistop/wps_illu10.svg'
      } as IProfiStopFeatures,
      {
        title: 'Are you stressed trying to organise many\nthing for your production?',
        description: 'We can only keep 7±2 things in our head at\nany one time. Let ProfiStop® take care of\nall your cutting data and management\nand you can focus on important things.',
        imageUrl: '/assets/why-profistop/wps_illu11.svg'
      } as IProfiStopFeatures,
      {
        title: 'Do you have two, thre or five saws\nin your production?',
        description: 'You can dramatically decrease space\nand labour allocated to cutting, while\nsimultaneously improving your\nproduction output.',
        imageUrl: '/assets/why-profistop/wps_illu12.svg'
      } as IProfiStopFeatures,
      {
        title: 'Do you discover onsite you have\nincorrectly manufactured parts?',
        description: 'Incorrectly manufactured parts are one of\nthe most commn and costly on-site work\nstoppages. Organise your production\nwith a ProfiStop® and no hole will be out of\nplace whn you need it the most.',
        imageUrl: '/assets/why-profistop/wps_illu13.svg'
      } as IProfiStopFeatures,
      {
        title: 'Do you have large, complex projects\nwith strict delivery times?',
        description: 'It is impossible to deliver large projects in\nshort time without efficient, automated\nproduction. Our machines have deliverd\nsome of the biggest, high-profile\nconstruction projects around Autralia.',
        imageUrl: '/assets/why-profistop/wps_illu14.svg'
      } as IProfiStopFeatures,
      {
        title: 'Is your best worker occupied\nwith cutting?',
        description: 'Cutting is a dull, labour and mind intensive\ntask. Have your best worker to focus on\nmore important things, and leave the\ndull work to ProfiStop®.',
        imageUrl: '/assets/why-profistop/wps_illu15.svg'
      } as IProfiStopFeatures,
      {
        title: 'Are you an owner-run business?',
        description: 'Hiring employees is the most challenging\nstep in the growth story of any business.\nProfiStop® is a low-cost way to dramatically\nincrease what you can output yourself and\ndecrease effort.',
        imageUrl: '/assets/why-profistop/wps_illu16.svg'
      } as IProfiStopFeatures,
      {
        title: 'We hear stories like this every day.',
        description: 'ProfiStop® purchase for our Customers is\ntypically a transformational investment\npaying itself off often in a matter of\nmonths.',
        imageUrl: '/assets/why-profistop/wps_illu17.png'
      } as IProfiStopFeatures
    ]);
  }

  ngOnInit(): void {
  }

}
