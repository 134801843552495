import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translationExists'
})
export class TranslationExistsPipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  async transform(value: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      await this.translateService.get(value).toPromise()
      .then(translation => {
        resolve(translation != null && translation !== value)
      })
      .catch(error => resolve(false));
    })
  }

}
