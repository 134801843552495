export enum eProductType {
  lazer = 0,
  alpha = 1,
  omicron = 2,
  aluminium = 3,
  steel = 4,
  timber = 5,
  cuttingLine = 6,
  special = 7,
  zero = 8
}

export enum eProductGroup {
  measuresAndLengthStops = 0,
  fullyAutomaticsSaws = 1
}
