<app-content-header [imageUrl]="'PRODUCTS.' + type + '.HEADER.IMAGE_URL' | translate">
  <div
    style="color: #fff; align-self: center; margin: 0 auto; line-height: 1.3; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
    <div
      style="position: absolute;flex: 1 1 auto;display: flex;align-items: center;justify-content: center;height: 100%;">
      <p style="white-space: break-spaces; margin: 64px 0; font-size: 20px;"
        [innerHTML]="'PRODUCTS.' + type + '.HEADER.TITLE' | translate"></p>
      <div
        style="display: grid; grid-template-columns: repeat(3, auto); column-gap: 24px; position: absolute; bottom: -16px; left: 16px;">
        <div class="header-details-caption">Product level</div>
        <div class="header-details-caption">Max Feed Weight</div>
        <div class="header-details-caption">Positioning Repeatability</div>
        <div class="header-details-value">{{ 'PRODUCTS.' + type + '.HEADER.DETAILS.PRODUCT_LEVEL' | translate }}</div>
        <div class="header-details-value">{{ 'PRODUCTS.' + type + '.HEADER.DETAILS.MAX_FEED_WEIGHT' | translate }}</div>
        <div class="header-details-value">{{ 'PRODUCTS.' + type + '.HEADER.DETAILS.POSITIOINING_REPEATABILITY' |
          translate }}</div>
      </div>
    </div>
  </div>
</app-content-header>
<div class="wrapper">
  <div class="inner-wrapper">
    <p *ngFor="let description of 'PRODUCTS.' + type + '.DESCRIPTION' | translate"> {{ description }}</p>
    <div style="position: relative;">
      <ul>
        <li *ngFor="let feature of 'PRODUCTS.' + type + '.FEATURES' | translate">{{ feature }}</li>
      </ul>
      <img src="/assets/default/download-brochure@500w.png"
        style="width: 200px; position: absolute; top: 15px; right: 15px" />
    </div>
    <div style="display: grid; grid-template-columns: calc(50% + 42px) auto; column-gap: 24px; margin: 64px 0;">
      <img [attr.src]="'PRODUCTS.' + type + '.USAGE.IMAGE_URL' | translate"
        style="max-width: 100%;align-self: center;width: 400px;" />
      <div style="align-self: center">
        <p style="padding: 0;">Ideal for:</p>
        <ul style="margin: 1em">
          <li *ngFor="let idealFor of 'PRODUCTS.' + type + '.USAGE.IDEAL_FOR' | translate">{{ idealFor }}</li>
        </ul>
        <img src="/assets/default/enquire-now.svg" style="max-width: 150px" />
      </div>
    </div>
    <div style="display: grid; grid-template-columns: auto calc(50% + 42px); column-gap: 24px; margin: 64px 0;">
      <p
        style="white-space: pre-line;display: flex;justify-content: flex-end;align-items: center;text-align: right;font-size: 22px;">
        {{ 'PRODUCTS.' + type + '.VIDEO.TITLE' | translate }}</p>
      <iframe width="400px" height="230" [attr.src]="'PRODUCTS.' + type + '.VIDEO.VIDEO_URL' | translate | safe"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
        style="max-width: 100%; justify-self: flex-end;"></iframe>
    </div>

    <div *ngIf="'PRODUCTS.' + type + '.TESTIMONIAL' | translationExists | async"
      style="display: grid; grid-template-columns: calc(50% + 42px) auto; column-gap: 24px; margin: 64px 0;">
      <img [attr.src]="'PRODUCTS.' + type + '.TESTIMONIAL.IMAGE_URL' | translate"
        style="max-width: 100%;align-self: center;width: 400px;" />
      <div>
        <p><span class="testimonial-signature" style="display: block;"
            *ngFor="let signature of 'PRODUCTS.' + type + '.TESTIMONIAL.SIGNATURE' | translate">{{
            signature }}</span></p>
        <p style="white-space: pre-line;">{{ 'PRODUCTS.' + type + '.TESTIMONIAL.DESCRIPTION' | translate }}</p>
      </div>
    </div>


    <div *ngIf="'PRODUCTS.' + type + '.VIDEO_SPECIFIC' | translationExists | async"
      style="display: grid; grid-template-columns: auto calc(50% + 42px); column-gap: 24px; margin: 64px 0;">
      <div>
        <p
          style="white-space: pre-line;display: flex;justify-content: flex-end;align-items: center;text-align: right;font-size: 22px;">
          {{ 'PRODUCTS.' + type + '.VIDEO_SPECIFIC.TITLE' | translate }}</p>
        <p style="white-space: pre-line;display: flex;justify-content: flex-end;align-items: center;text-align: right;">
          {{ 'PRODUCTS.' + type + '.VIDEO_SPECIFIC.DESCRIPTION' | translate }}</p>
      </div>
      <iframe width="400px" height="230"
        [attr.src]="'PRODUCTS.' + type + '.VIDEO_SPECIFIC.VIDEO_URL' | translate | safe" title="YouTube video player"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen style="max-width: 100%; justify-self: flex-end;"></iframe>
    </div>

    <div
      style="display: grid; grid-template-columns: repeat(2, 1fr); column-gap: 24px; margin-top: 72px; margin-bottom: 32px; text-align: center; line-height: 1.3;">
      <div style="display:flex; flex-direction: column; row-gap: 12px;">
        <img src="/assets/products/icon_01.svg" style="max-height: 42px;" />
        <strong>Do you have large, complex projects<br>with strict delivrey times?</strong>
        <p>
          It is impossible to deliver large projects in<br>short time without efficient, automated<br>
          production. Our machines have delivered<br>some of the biggest, high-profile projects<br>around Australia.
        </p>
      </div>
      <div style="display:flex; flex-direction: column; row-gap: 12px;">
        <img src="/assets/products/icon_02.svg" style="max-height: 42px;" />
        <strong>Are you so busy that you can<br>no take any more work</strong>
        <p>
          Every month you are likely losing more in<br>sales and profit than the cost of investment<br>
          in a ProfiStop®
        </p>
      </div>

    </div>
  </div>

  <!-- PRODUCTIVITY IN FOCUS SECTION -->
  <div *ngIf="'PRODUCTS.' + type + '.PRODUCTIVITY_IN_FOCUS' | translationExists | async" style="width: 100%;">

    <div style=" width: 100%; height: 375px; position: relative; background-position: center center; background-size: cover; font-weight: 600;" [ngStyle]="{'background-image':'url(' + ('PRODUCTS.' + type + '.PRODUCTIVITY_IN_FOCUS.BANNER_IMAGE_URL' | translate) + ')' }">
      <div style="width: 790px; margin: 0 auto; padding: 64px 0" [ngStyle]="('PRODUCTS.' + type + '.PRODUCTIVITY_IN_FOCUS.MESSAGE_POSITION' | translate) == 0 ? {'text-align':'left'} : {'text-align':'right'}">
        <span style="font-size: 2.5em;">ProfiFeed® Auto Saws</span><br><span style="font-size: 2em;">Cutting as Easy as
          Friday</span>
      </div>
    </div>

    <div style="margin: 64px auto; width: 790px;">
      <h3 style="font-size: 1.7em; text-align: center;">ProfiStop®<br>Productivity in Focus</h3>
      <div
        style="text-align: center; margin: 64px; display: grid; grid-template-columns: repeat(2, 1fr); align-items:center;justify-content:center">
        <div
          style="height: 60px; width: 100%; margin-bottom: 4px; display: flex; align-items: center; justify-content: center;">
          <img src="/assets/products/productivity-icon_01.svg" style="max-height: 60px; max-width: 100px;">
        </div>
        <div
          style="height: 60px; width: 100%; margin-bottom: 4px; display: flex; align-items: center; justify-content: center;">
          <img src="/assets/products/productivity-icon_02.svg" style="max-height: 60px; max-width: 100px;">
        </div>
        <h4 style="margin: 1em;">On-Screen Automatic Yield Optimisation</h4>
        <h4 style="margin: 1em;">Automatic Defect Removal</h4>
        <p style="margin: 1em;">Get the benefit of reduced material wastage <br>but without wasting time managing stock. <br>Send the same cut list you use today to <br>ProfiStop®. On-the-fly optimisation <br>automatically measures the length of <br>stock, optimises your job list for <br>maximum material yield, and ProfiStop® <br>Auto Saw makes the cuts.</p>
        <p style="margin: 1em;">Don't let any scratches through with ProfiS-<br>top® on-the-fly defect removal and optimisa-<br>tion. Your operator marks the defects using a <br>pen, ProfiStop® system scans the marked lo-<br>cations and automatically cuts out the dam-<br>aged material while cutting your parts with <br>maximum material utilisation from the re-<br>maining defect-free material.</p>
        <div
          style="margin-top: 42px; height: 60px; width: 100%; margin-bottom: 4px; display: flex; align-items: center; justify-content: center;">
          <img src="/assets/products/productivity-icon_03.svg" style="max-height: 60px; max-width: 100px;">
        </div>
        <div
          style="margin-top: 42px; height: 60px; width: 100%; margin-bottom: 4px; display: flex; align-items: center; justify-content: center;">
          <img src="/assets/products/productivity-icon_04.svg" style="max-height: 60px; max-width: 100px;">
        </div>
        <h4 style="margin: 1em;">Automatic Part Marking</h4>
        <h4 style="margin: 1em;">OptiCut-1D™</h4>
        <p style="margin: 1em;">Sort out your many parts daily by labelling <br>them. Our automatic saws can print job or <br>part information on your parts by burning or <br>pin marking automatically for every cut <br>made.</p>
        <p style="margin: 1em;">Our cloud-based design BOMS, job and stock <br>management solution seamlessly integrated <br>with your ProfiStop® Auto Saw. Automati-<br>cally group and optimise your cutting jobs, <br>and sending the cut list to your auto saw is <br>as easy as drag-and-drop. Use OptiCut-1D™ <br>for managing all your design BOMs, jobs, <br>stock and material procurement.</p>
      </div>
    </div>

    <div style="display: flex; align-items: center; justify-content: center;">
    <img [attr.src]="'PRODUCTS.' + type + '.PRODUCTIVITY_IN_FOCUS.FOOTER_IMAGE_URL' | translate" style="width: 100%; max-width: 790px;" >
  </div>

  </div>

</div>
