import { Component, Input, OnInit } from '@angular/core';
import { title } from 'process';
import { IProductsGroupButton } from 'src/services/models/products-group-button';

@Component({
  selector: 'app-products-button',
  templateUrl: './products-button.component.html',
  styleUrls: ['./products-button.component.sass']
})
export class ProductsButtonComponent implements OnInit {

  @Input('source') source: IProductsGroupButton = null;
  @Input('position') position: number = 0;

  constructor() { }

  ngOnInit(): void {
    if (this.source == null) {
      this.source = { title: 'Title missing', description: 'Description missing', url: '#', imageUrl: '' };
    }
  }

}
