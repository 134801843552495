import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { eProductType } from 'src/services/models/enums';
import { IProduct } from 'src/services/models/product';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.sass']
})
export class ProductComponent implements OnInit {

  @Input('type') type: string;

  private allowedTypes: string[] = [
    eProductType[eProductType.alpha].toUpperCase(),
    eProductType[eProductType.lazer].toUpperCase(),
    eProductType[eProductType.zero].toUpperCase(),
    eProductType[eProductType.omicron].toUpperCase(),
    eProductType[eProductType.aluminium].toUpperCase(),
    eProductType[eProductType.steel].toUpperCase(),
    eProductType[eProductType.timber].toUpperCase(),
    eProductType[eProductType.cuttingLine].toUpperCase()
  ];

  constructor(private router: Router, private route: ActivatedRoute) {
    this.type = eProductType[route.snapshot.data['type']]?.toUpperCase();
  }

  ngOnInit(): void {
    if (this.type == null || !this.allowedTypes.some(value => value === this.type)) {
      this.router.navigate(['/']);
    }
  }

}
