import { Component, Input, OnInit } from '@angular/core';
import { IProductButton } from 'src/services/models/product-button';

@Component({
  selector: 'app-product-button',
  templateUrl: './product-button.component.html',
  styleUrls: ['./product-button.component.sass']
})
export class ProductButtonComponent implements OnInit {

  @Input('source') source: IProductButton;

  constructor() { }

  ngOnInit(): void {
    if (this.source == null) {
      this.source = { title: '', description: '', url: '#', image_url: '' };
    }
  }

}
