import { Component, OnInit } from '@angular/core';
import { IProductTimeline } from 'src/services/models/product-timeline';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.sass']
})
export class WhoWeAreComponent implements OnInit {

  public productsTimeline: IProductTimeline[] = [];

  constructor() {
    this.productsTimeline.push(...[
      {
        year: 2009, productLines: 6, imageUrl: '/assets/who-we-are/products-timeline/2009.png'
      } as IProductTimeline,
      {
        year: 2011, productLines: 23, imageUrl: '/assets/who-we-are/products-timeline/2011.png'
      } as IProductTimeline,
      {
        year: 2012, productLines: 50, imageUrl: '/assets/who-we-are/products-timeline/2012.png'
      } as IProductTimeline,
      {
        year: 2015, productLines: 80, imageUrl: '/assets/who-we-are/products-timeline/2015.png'
      } as IProductTimeline,
      {
        year: 2016, productLines: 110, imageUrl: '/assets/who-we-are/products-timeline/2016.png'
      } as IProductTimeline,
      {
        year: 2019, productLines: 132, imageUrl: '/assets/who-we-are/products-timeline/2019.png'
      } as IProductTimeline,
    ])
  }

  ngOnInit(): void {
  }

}
